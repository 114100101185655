export const projects = [
  {
    title: "Last Pay Wins",
    desc: "An addictive game that illustrates the Lightning Network’s instant settlement. Collected/distributed >2.5mil sats in its first week.",
    under: "Won 2nd Place at the Pleb.fi Miami 2023.",
    img: "https://user-images.githubusercontent.com/43247027/240331021-6a2a46aa-63aa-4543-b5dd-19d1b9b1f661.png",
    link: "https://lastpaywins.com",
    code: "https://github.com/alexlwn123/LastPayWins",
    tweet: "https://twitter.com/PlebFi/status/1658986375327150080",
    place: 2,
    event: 'https://pleb.fi/miami2023/'
  },
  {
    title: "Pleb FM",
    desc: "Built a lightning-powered Jukebox / radio-station. It’s an ongoing auction for the next song to be played.",
    under: "Won 1st Place at the SatsxSW 2022 Hackathon. Raised over 2 million satoshis at TABConf 2023",
    img: "https://github.com/PlebFM/PlebFM/assets/43247027/d4c9bccb-e99e-493e-96a5-7e429e90cdd6",
    link: "https://pleb.fm/",
    code: "https://github.com/plebfm/plebfm",
    video: "https://youtu.be/_AwXSzzc9k0?t=839",
    tweet: "https://x.com/atlbitlab/status/1701359384851292201",
    place: 1,
    event: 'https://www.thrillerbitcoin.com/satsx-hackathon-by-pleb-lab-day-2/'
  },
  {
    title: "Auntie LN",
    desc: "Proof of concept for verifiable communication between lightning nodes on Nostr. Creates an alternative transport layer for the Lightning Network.",
    under: "Won 1st Place at the SatsxSW 2023 Hackathon.",
    img: "https://pbs.twimg.com/media/FrnqVsjWYAMILkH?format=jpg&name=large",
    link: "https://bolt.fun/project/auntie-ln",
    code: "https://github.com/alexlwn123/thunderhub",
    video: "https://youtu.be/JB5tyLmPBqU",
    tweet: "https://twitter.com/PlebLab/status/1637628536260292608",
    place: 1,
    event: 'https://twitter.com/BlockSpaces_io/status/1637642107048402944'
  },
  {
    title: "Tree-pay UX",
    desc: "Created a UX for congestion-controlled bitcoin transactions using CTV.",
    under: "Tied for 2nd place at the Pleb.fi Miami 2022 Hackathon.",
    img: "https://pbs.twimg.com/media/FPn80SsWUAACzEv?format=jpg&name=large",
    link: "https://www.figma.com/file/l3q7Qn5IB2H45XF5QJ39tE/TreePay-UX?node-id=0%3A1",
    code: "https://github.com/atlantabitdevs/treepay-ux",
    tweet: "https://twitter.com/JeremyRubin/status/1511517162099380230",
    place: 2,
    event: 'https://pleb.fi/miami2022/'
  },
  {
    title: "Arion Parking",
    desc: "Created a next-gen parking app that uses the Lightning Network for payments.",
    under: "Won 1st place at the Bolt.fun Shock The Web Hackathon.",
    img: "https://github.com/atlantabitdevs/park-lightning/blob/master/arion.png?raw=true",
    link: "https://arionparking.tech/",
    video: "https://www.youtube.com/watch?v=kMpse9ChX3g",
    code: "https://github.com/atlantabitdevs/park-lightning",
    tweet: "https://twitter.com/boltfun_btc/status/1508538497581424640",
    place: 1,
    event: "https://twitter.com/boltfun_btc/status/1508538497581424640"
  },
  {
    title: "Chesscup Hack",
    desc: "Created a bot that uses browser automation to quickly solve puzzles on Chesscup.",
    under: "Currnetly holds all world records on the site.",
    img: "https://user-images.githubusercontent.com/43247027/215305511-7c58f261-0b5e-41b9-a776-c9b6d38920b4.png",
    link: "https://chesscup.org/",
    code: "https://github.com/alexlwn123/Chesscup-hack",
    tweet: "https://twitter.com/_AlexLewin/status/1673532600143560705", 
  }
];
